import React from "react";
import "./eventsContainer.css";
import { EventCard } from "../../components";

import images from "../../assets/images";

function EventsContainer(props) {
  const details = [
    {
      event: "Extravaganza",
      date: "19th October 2024",
      description: "MKUMSA 10th Year Anniversary  ",
      link: "/events",
      image: images.extravaganza,
    },
  ];
  return (
    <div className="eventsContainer section-padding">
      <h2 className="gradient-text underline">Events</h2>

      <div className="eventsContainer-cards">
        <div className="eventsContainer-cards-upcoming">
          <h3 className="underline">Recent</h3>
          <div className="eventsContainer-upcoming-container">
            {details.map((details, index) => (
              <div key={index}>
                <EventCard details={details} />
              </div>
            ))}
          </div>
        </div>

        <div className="eventsContainer-cards-pastEvents">
          <h3 className="underline">Past Events</h3>

          <div className="pastEvents-card">
            <p>Funday 2024</p>
            <p>Padrive 2024</p>
            <p>Ideathon 2024</p>
            <p>Health talks 2024</p>
            <p>Gala 2023</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsContainer;
