import React from "react";
import {
  Accordion,
  Hero,
  ImageView,
  NavBar,
  ProfileCard,
  TextArea,
} from "../../components";
import { Footer } from "../../containers";

import colors from "./colors";

import "./sCommittee.css";
import { Helmet } from "react-helmet";
import images from "../../assets/images";

function SCOME(props) {
  const heroDetails = {
    color: colors.scome,
    title: "Medical Education (SCOME)",
    description:
      "Medical Education should be a concern of every medical student as it shapes not only the quality of future doctors but also the quality of healthcare. The Mount Kenya University Medical students Association (MKUMSA) has a dedicated organ which aims to implement an optimal learning environment for all medical students around the world, the Standing Committee On Medical Education (SCOME). Through all our joint efforts we work to create sustainable changes around the world, for ourselves as medical students, for the generations to come and for our future patients and our communities who are in fact the final beneficiaries of our education.",
  };

  const content = [
    {
      title: "Vision",
      text: `Medical students are meaningfully engaged within their respective Medical Education Systems and represent student voice in issues regarding Medical Education as full-fledged stakeholders on all levels in order to create quality Medical Education worldwide and attain suitable competencies which will enable them to answer the healthcare needs of communities they have a mandate to serve.`,
    },

    {
      title: "Mission",
      text: `SCOME is a platform that creates a shared environment where medical students contribute, reflect, and work on the development of Medical Education through student-led activities. SCOME enables medical students to reach their full potential as Medical Education leaders through activities aimed at education, research, capacity development, meaningful youth engagement and, advocacy in order to create an effective impact on a local, national, regional, and international level.`,
    },

    {
      title: "Main topics",
      text: `Healthcare is evolving at an extraordinary pace, yet medical schools are slow in updating their curricula to match the advancements. Despite the increasing knowledge of medical science, many faculty members are doctors who lack teaching skills. Medical students, such as those in the Mount Kenya University Medical Students’ Association (MKUMSA), are directly exposed to the curriculum, making them essential in evaluating and influencing its development.
</br> </br>
      The graduates' ability to meet professional standards and community needs forms the second check on the effectiveness of medical education. MKUMSA members, as part of the larger MSAKE (Medical Students Association of Kenya) and under IFMSA, are strong advocates for aligning medical education with the healthcare needs of their communities.
      </br></br>
      While modern curricula can better equip students with relevant skills, changes happen slowly, often overlooked by universities. SCOME (Standing Committee on Medical Education) of IFMSA plays a crucial role in advocating for updated and innovative medical education. By providing training, exchanging knowledge, and promoting better teaching methods, MKUMSA, through MSAKE and IFMSA, contributes to ensuring that medical students are prepared to meet modern healthcare challenges.`,
    },
  ];

  const galleryImages = [
    // {
    //   image: hero,
    //   description: "the research team",
    // },
    // {
    //   image: hero,
    //   description: "the research team",
    // },
  ];

  const leaders = [
    {
      name: "Valerie Chemutai",
      role: "Local Officer",
      email: "mkuscome@gmail.com",
      linkedin:
        "https://www.linkedin.com/in/valerie-chemutai-rotich-371751217?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app&original_referer=https%3A%2F%2Fmail.google.com%2F",
      picture: images.val,
    },
    {
      name: "Naomi Oron",
      role: "Assistant Local Officer",
      email: "email",
      linkedin: "",
    },
  ];

  const previousLeaders = [
    { title: "2023", content: "Kelvin Kapaya" },
    { title: "2022", content: "Edna Kerubo" },
    { title: "2021", content: "Kironyo John" },
  ];
  return (
    <div>
      <Helmet>
        <title>SCOME</title>
        <meta
          name="description"
          content="Medical Education should be a concern of every medical student as it shapes not only the quality of future doctors but also the quality of healthcare. The Mount Kenya University Medical students Association (MKUMSA) has a dedicated organ which aims to implement an optimal learning environment for all medical students around the world, the Standing Committee On Medical Education (SCOME). Through all our joint efforts we work to create sustainable changes around the world, for ourselves as medical students, for the generations to come and for our future patients and our communities who are in fact the final beneficiaries of our education."
        />
      </Helmet>
      <NavBar />
      <Hero details={heroDetails} />
      <div className="specificCommittee bodyBg section-padding">
        {content.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
          </div>
        ))}

        <h2 className="underline gradient-text">Leadership</h2>
        <div className="leaders-container">
          {leaders.map((leader, index) => (
            <div key={index}>
              <ProfileCard leader={leader} />
            </div>
          ))}
        </div>
        <h2 className="underline gradient-text">Gallery</h2>
        <div className="image-container">
          {galleryImages.map((details, index) => (
            <div key={index}>
              <ImageView details={details} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Previous Leaders</h2>

        <Accordion details={previousLeaders} />
      </div>

      <Footer />
    </div>
  );
}

export default SCOME;
