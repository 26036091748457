import React from "react";
import "./home.css";
import { NavBar } from "../../components";
import {
  BlogContainer,
  Contacts,
  EventsContainer,
  Footer,
  Header,
  Partners,
  Publication,
  WhoAreWe,
} from "../../containers";
import { Helmet } from "react-helmet";

function Home(props) {
  return (
    <div>
      <Helmet>
        <title>Mount Kenya University Medical Students Association</title>

        <meta
          name="description"
          content="The Mount Kenya University Medical Students' Association (MKUMSA) is
      the local organization that accommodates the medical students of Mount
      Kenya University. MKUMSA is affiliated with The Medical Students'
      Association of Kenya (MSAKE) and The International Federation of
      Students Association (IFMSA)....."
        />
        <meta
          property="og:image"
          content="https://i.postimg.cc/7hRqzmcT/mkumsa.png"
        />
      </Helmet>
      <NavBar />
      <Header />
      <WhoAreWe />
      <Publication />
      <BlogContainer />
      <Partners />
      <EventsContainer />
      <Contacts />
      <Footer />
    </div>
  );
}

export default Home;
