import React from "react";
import "./imageView.css";

// image and description
function ImageView({ details = {} }) {
  return (
    <div className="imageView">
      <div className="imageView-container">
        <img src={details.image} alt="" />
      </div>
      <p className="imageView-description">{details.description}</p>
    </div>
  );
}

export default ImageView;
