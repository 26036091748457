import React from "react";
import "./profileCard.css";
import profileBg from "../../assets/profileBg.jpg";
import { CiLinkedin } from "react-icons/ci";
import profile from "../../assets/profile.jpg";

import { MdOutlineEmail } from "react-icons/md";

// need picture , name , role , linkedin , gmail
function ProfileCard({ leader = {} }) {
  const bgPic = {
    backgroundImage: `url(${profileBg})`,
    backgroundRepeat: "no-repeat",
  };

  const profilePic = {
    backgroundImage: `url(${leader.picture ? leader.picture : profile})`,
    backgroundRepeat: "no-repeat",
  };

  const openLink = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="profileCard">
      <div className="background"></div>

      <div class="outer-div">
        <div className="inner-div">
          <div className="front">
            <div className="front__bkg-photo" style={bgPic}></div>
            <div className="front__face-photo" style={profilePic}></div>
            <div className="front__text">
              <h3 className="front__text-header">{leader.name}</h3>
              <p className="front__text-para">
                <i className="fas fa-map-marker-alt front-icons"></i>
                {leader.role}
              </p>

              <span className="front__text-hover">Socials</span>
            </div>
          </div>
          <div className="back">
            <div className="social-media-wrapper">
              <div
                onClick={() => openLink(leader.linkedin)}
                className="social-icon"
              >
                <CiLinkedin className="fab " aria-hidden="true" />
              </div>
              <a href={`mailto:${leader.email}`}>
                <div className="social-icon">
                  <MdOutlineEmail className="fab " aria-hidden="true" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
