import React from "react";
import "./blogContainer.css";

function BlogContainer(props) {
  return (
    <div className="blogContainer section-padding">
      <div className="blogContainer-text">
        <h2 className="whiteUnderline gradient-text">Blogs</h2>

        <p>Coming soon</p>
      </div>
    </div>
  );
}

export default BlogContainer;
