import "./App.css";

import { Navigate, Route, Routes } from "react-router-dom";
import {
  AboutUs,
  Clubs,
  HustleCorner,
  Events,
  Faq,
  Gallery,
  Home,
  NotFound,
  SCORE,
  SCORA,
  SCOMH,
  SCORP,
  SCOPH,
  SCOPE,
  SCOME,
  SCommittees,
} from "./pages";
import { ScrollToTop } from "./components";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route element={<Home />} path="" />
        <Route element={<SCommittees />} path="/standing-Committees" />
        <Route element={<Gallery />} path="/gallery" />
        <Route element={<AboutUs />} path="/about-Us" />
        <Route element={<Clubs />} path="/mkumsa-clubs" />
        <Route element={<Events />} path="/events" />
        <Route element={<HustleCorner />} path="/hustle-corner" />
        <Route element={<Faq />} path="/faq" />
        <Route element={<SCORE />} path="/standing-committees/score" />
        <Route element={<SCORA />} path="/standing-committees/scora" />
        <Route element={<SCOME />} path="/standing-committees/scome" />
        <Route element={<SCOMH />} path="/standing-committees/scomh" />
        <Route element={<SCORP />} path="/standing-committees/scorp" />
        <Route element={<SCOPH />} path="/standing-committees/scoph" />
        <Route element={<SCOPE />} path="/standing-committees/scope" />

        <Route element={<NotFound />} path="/not-found" />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
    </div>
  );
}

export default App;
