import React from "react";
import "./events.css";
import Navbar from "../../components/navbar/Navbar";
import { Footer } from "../../containers";
import { Hero, ImageView, TextArea } from "../../components";
import blog from "../../assets/blog.jpg";
import { Helmet } from "react-helmet";

function Events(props) {
  const openLink = (url) => {
    window.open(url, "_blank");
  };
  const events = [
    {
      title: "Extravaganza",
      text: `<p>The <strong>MKUMSA Extravaganza</strong> is an important event as it marks <strong>10 years</strong> since the association was founded. We will also be celebrating our <strong>finalists</strong> with a special finalist dinner.</p>

      <p><strong>Join us on October 19th</strong> at the <strong>Westlands Banquet Centre</strong> for a night filled with elegance and glamour.</p>

      <p><strong>Details:</strong></p>
      <ul>
        <li><strong>Dress Code:</strong> Black-tie attire</li>
        <li><strong>Tickets:</strong>
          <ul>
            <li><strong>Early Bird:</strong> 2,000 Ksh (available until October 4th)</li>
            <li><strong>Late Bird:</strong> 2,500 Ksh</li>
          </ul>
        </li>
      </ul>

      <p>Prepare to be captivated by the magic of the evening. This is an event you won’t want to miss!</p>
      `,
      link: "https://docs.google.com/forms/d/e/1FAIpQLSfkGpxbIgqexW7rfX6SkJAKACi5oTespo6XQ4XfcZCf4rir5A/viewform",
      imageDetails: {
        image: blog,
        description: "This is the description of the image #gala",
      },
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Events</title>
        <meta
          name="description"
          content="Here are some of the Upcoming and past events of MKUMSA."
        />
      </Helmet>
      <Navbar />
      <Hero details={{ title: "Events" }} />
      <div className="events  bodyBg section-padding">
        <h2 className="underline gradient-text">Events</h2>

        {events.map((event, index) => (
          <div key={index}>
            <ImageView details={event.imageDetails} />
            <TextArea details={event} />

            <button
              onClick={() => {
                openLink(event.link);
              }}
              className="redButton "
            >
              Register
            </button>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Events;
