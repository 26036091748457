import React from "react";
import "./whoAreWe.css";

import { Wecard } from "../../components";

import { IoIosPeople } from "react-icons/io";
import { FaHands } from "react-icons/fa";
import { TbBrandUnity } from "react-icons/tb";

function WhoAreWe(props) {
  const details = [
    {
      logo: <TbBrandUnity size={60} color="var(--color-text)" />,
      text: "7 Standing committees",
      link: "/standing-committees",
    },
    {
      logo: <IoIosPeople size={60} color="var(--color-text)" />,
      text: "700 + Medical Students",
    },
    {
      logo: <FaHands size={60} color="var(--color-text)" />,
      text: "10 Years of service",
    },
  ];

  return (
    <div className="whoAreWe bodyBg section-padding ">
      <div className="whoAreWe-text">
        <h2 className="gradient-text underline">Who are we ?</h2>
        <p>We represent</p>
      </div>

      <div className="whoAreWe-cards">
        {details.map((details, index) => (
          <div key={index}>
            <Wecard details={details} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhoAreWe;
