import React from "react";
import "./clubs.css";
import Navbar from "../../components/navbar/Navbar";
import { Footer } from "../../containers";
import { ImageView, Hero, TextArea } from "../../components";
import blog from "../../assets/blog.jpg";
import { Helmet } from "react-helmet";
import images from "../../assets/images";

function Clubs(props) {
  const heroDetails = { title: "Clubs and Associations ", image: images.clubs };
  const clubs = [
    {
      title: "Med Code Community",
      text: `The MKU Med Code Community unites medical students with a shared passion for
      technology, including fields such as data science, design, video editing, blockchain, and
      more. Our primary goal is to foster a community of like-minded individuals and inspire
      more doctors to explore and engage with the world of technology. `,

      leader: `Founder - Emmanuel Riri </br> Co-founder -  Francis Koinange`,
      imageDetails: {
        image: images.codeCommunity,
        description: "image description",
      },
    },
    {
      title: "Mr and Miss Mkumsa",
      text: ` Mr. and Miss MKUMSA serve as the vibrant faces of the MKU
      Medical Students’ Association, embodying leadership, integrity, and excellence. As
      ambassadors, we represent the values of MKUMSA both within the institution and in the
      larger community, working to inspire our peers through initiatives that promote unity, social
      responsibility, and academic success. Through this platform, we champion student
      causes, engage in outreach programs, and foster a sense of pride and collaboration among
      the medical students, making them integral to the MKUMSA vision. `,
      leader: `
      Mr Mkumsa - Collins Muema </br> Miss Mkumsa - Seleyian Margaret
    `,
      imageDetails: {
        image: images.missMkumsa,
        description: "",
      },
    },
    {
      title: "MKUMSART",
      text: `MKUMSART is a body mandated to maginify talents within Mount Kenya university Medical school. </br> We identify,  offer platforms and
      spaces to showcase art of different forms within our medical school community.Be it music,artwork,content creation , photography, film ,
      creative writing we are a go to place for things art for our doctors. `,

      imageDetails: {
        image: images.mkusart,
        description: "#mkumsart",
      },
    },

    {
      title: "MKUMSA SEALS",
      text: `This is a club that embraces a passion in the art of swimming, the willingness to learn the
      art as a beginner, as well as training the techniques gained among MKU medical students.
      Its fundamental goal is to promote mental health awareness among its members and
      encourage as many MKU medical students, to delve into co-curricula activities that
      embrace fitness as well.`,

      imageDetails: {
        image: images.swimming,
        description: " ",
      },
    },

    {
      title: "PSYCHYWELL",
      text: `PsychyWell is a mental health club by medical students for medical students. We aim to
      promote mental wellness, provide support and create awareness by fostering a supportive
      community and encouraging open discussions about mental health. We hope to empower
      students to prioritize their well-being alongside their academic pursuits.`,
      leader: `President - Anita Otieno </br> Vice President - Sion Sharlyn`,
      imageDetails: {
        image: blog,
        description: " ",
      },
    },

    {
      title: "Football Team",
      text: ` Our football club is dedicated to fostering strong relationships
      among medical students while providing a platform for talent exploration and
      development. Through football, we promote physical and mental well-being, helping
      students enhance key skills like teamwork, leadership, and resilience—qualities that are
      essential for healthcare professionals. We believe sports play a vital role in building
      connections and mutual support within the medical community. </br>
      We organize regular training sessions, friendly matches, and participate in university and
      inter-university tournaments. Our goals include promoting an active and healthy lifestyle,
      fostering a sense of teamwork and sportsmanship, and creating a supportive, inclusive
      environment where all members can grow both personally and as part of a team. By doing
      so, we help students bond, improve their fitness, and showcase their football skills.`,
      leader: `Captain - Stephen Ayieko </br> Assistant Captain - Baam John`,
      imageDetails: {
        image: images.mbchbFa,
        description: " ",
      },
    },

    {
      title: "MKU MED CHESS ClUB",
      text: `The Mount Kenya University Med Chess Club unites medical students who share a passion for chess and stategic thinking. Our
      mission is to enhance cognitive skills , promote mental well-being , and foster comaraderie through regular matches,tournaments, and workshops.
      We aim to develop critical thinking and teamwork abilities essential in both chess and medicine, creating a vibrant community that supports players of all skill levels
      Led by a dedicated team, we strive to provide an inclusive environment where members can grow and enjoy the game together. `,

      imageDetails: {
        image: blog,
        description: " ",
      },
    },
  ];
  return (
    <div>
      <Helmet>
        <title>MKUMSA Clubs</title>

        <meta
          name="description"
          content="These are some of the clubs and associations that are under MKUMSA"
        />
      </Helmet>
      <Navbar />
      <Hero details={heroDetails} height="75vh" />
      <div className="clubs section-padding bodyBg">
        <h2 className="underline gradient-text">
          Clubs and associations Under MKUMSA
        </h2>

        {clubs.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
            <ImageView details={details.imageDetails} />

            <h5
              key={index}
              dangerouslySetInnerHTML={{ __html: details.leader }}
            />
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default Clubs;
