import React from "react";
import "./sCard.css";
import { Link } from "react-router-dom";

// requires background , color , logo, title, text, link
function SCcard({ details = {} }) {
  const style = {
    color: details.color ? details.color : "",
    background: details.background,
  };
  const linkStyle = {
    textDecoration: "none",
  };
  return (
    <div className="scard" style={style}>
      <div className="scard-logo">
        <img src={details.logo} alt="logo" />
      </div>

      <h4
        style={{ color: details.color ? details.color : "" }}
        className="scard-title  underline"
      >
        {details.title}
      </h4>

      <p>{details.text}</p>

      <Link style={linkStyle} to={details.link} className="redButton">
        View Committee
      </Link>
    </div>
  );
}

export default SCcard;
