import React from "react";
import {
  Accordion,
  Hero,
  ImageView,
  NavBar,
  ProfileCard,
  TextArea,
} from "../../components";
import { Footer } from "../../containers";

import colors from "./colors";

import "./sCommittee.css";
import { Helmet } from "react-helmet";
import images from "../../assets/images";

function SCOPE(props) {
  const heroDetails = {
    color: colors.scope,
    title: "Professional Exchanges (SCOPE)",
    description: `The Professional Exchange program, established by the International Federation of Medical Students' Associations (IFMSA) in 1951, enables medical students to participate in clerkships abroad. For Mount Kenya University Medical Students’ Association (MKUMSA) members, under MSAKE and IFMSA, it provides an opportunity to engage with diverse healthcare systems and cultures. Each year, around 13,000 students from over 90 countries participate, enhancing their global health knowledge and intercultural understanding. This program offers MKUMSA students valuable international exposure, contributing to both their professional and personal growth in their medical careers.`,
    image: images.scopeHero,
  };

  const content = [
    {
      title: "Mission",
      text: `The aim of SCOPE is to promote cultural understanding and co-operation amongst medical students and all health professionals, through the facilitation of international student exchanges. SCOPE aims to give all students the opportunity to learn about global health, and attains this partly by having its exchanges accredited by medical faculties across the world.`,
    },

    {
      title: "Objectives",
      text: `<ul>
      <li>To increase the mobility and to widen the horizon of medical students worldwide;</li>
      <li>To provide equal chances to medical students to participate in a professional exchange, regardless of subjective, geographical, political, financial, sexual, cultural or religious backgrounds;</li>
      <li>To provide medical students with the possibility to experience healthcare in another culture with different health and education systems, and to learn how differences in culture and beliefs are of influence;</li>
      <li>To create possibilities for medical students to learn about global health issues, primary health concerns and basic epidemiology of the host country, and how it differs from their home country;</li>
      <li>To contribute to the education of future health professionals with a global vision and to contribute to medical students’ personal development, self-reliance, and openness in becoming future health professionals;</li>
      <li>To provide students with the chance to improve their medical knowledge, their vision on medical issues and their practical knowledge depending on the regulations of the host country;</li>
      <li>To facilitate the connection between medical students and other health professionals and to provide a platform for future cooperation amongst medical students with each other and with health professionals across the globe;</li>
      <li>To maintain affordable professional exchange tuition through its governing body to ensure that medical students within the National Member Organizations can participate in the exchanges with a minimal financial burden;</li>
      <li>To make sure students are aware of the ethical aspects regarding their exchange to assure the burden on society, patients, the resources, and the healthcare system is as limited as possible;</li>
      <li>To promote tolerance towards differences and similarities within health and towards patients regardless of their sex, religion, or beliefs.</li>
    </ul>
    `,
    },

    {
      title: "Main Topics",
      text: `<p>All medical students, including members of the Mount Kenya University Medical Students’ Association (MKUMSA) under MSAKE and IFMSA, are eligible to participate in a four-week clerkship in a chosen clinical or preclinical field. The language of instruction is either English or the local language of the host country. To complete the clerkship, students must demonstrate proficiency in English or the native language of the host.</p>

        <p>These clerkships are fully educational with no salary provided, giving students the opportunity to observe and learn from a different healthcare system under the guidance of a physician supervisor, who serves as both a tutor and mentor.</p>

        <p>Exchange contracts are signed between National Member Organizations (NMOs), with two types of agreements:</p>

        <ul>
          <li><strong>Bilateral Contracts:</strong> Two students, one from each participating country, are exchanged.</li>
          <li><strong>Unilateral Contracts:</strong> A single student from one country participates in the exchange.</li>
        </ul>

        <p>For a participation fee, incoming students receive a one-month clerkship at the hospital and department of their choice, accommodation either at a student facility or with a host family, and at least one meal per day. This exchange program offers MKUMSA students a valuable opportunity to enhance their medical training and gain international exposure.</p>
        `,
    },
  ];

  const galleryImages = [
    {
      image: images.scopeHero,
      description: "",
    },
  ];

  const leaders = [
    {
      name: "Mark Mutugi",
      role: "Local Officer",
      email: "markmutugimutuma@gmail.com",
      linkedin: `https://www.linkedin.com/in/mark-mutugi-7a7403220
      `,
      picture: images.mutugi,
    },
    {
      name: "Salome Kaberere",
      role: "Assistant Local Officer",
      email: "",
      linkedin: "",
      picture: images.salome,
    },
  ];

  const previousLeaders = [
    { title: "2023", content: "Mwaniki Murigi" },
    { title: "2022", content: "Eugevyle Wachira" },
    { title: "2021", content: "Dr. Nimo Abdikadir Dagane" },
  ];
  return (
    <div>
      <Helmet>
        <title>SCOPE</title>
        <meta
          name="description"
          content="The Professional Exchange program, established by the International Federation of Medical Students' Associations (IFMSA) in 1951, enables medical students to participate in clerkships abroad. For Mount Kenya University Medical Students’ Association (MKUMSA) members, under MSAKE and IFMSA, it provides an opportunity to engage with diverse healthcare systems and cultures. Each year, around 13,000 students from over 90 countries participate, enhancing their global health knowledge and intercultural understanding. This program offers MKUMSA students valuable international exposure, contributing to both their professional and personal growth in their medical careers."
        />
      </Helmet>
      <NavBar />
      <Hero details={heroDetails} />
      <div className="specificCommittee bodyBg section-padding">
        {content.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
          </div>
        ))}

        <h2 className="underline gradient-text">Leadership</h2>
        <div className="leaders-container">
          {leaders.map((leader, index) => (
            <div key={index}>
              <ProfileCard leader={leader} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Gallery</h2>
        <div className="image-container">
          {galleryImages.map((details, index) => (
            <div key={index}>
              <ImageView details={details} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Previous Leaders</h2>

        <Accordion details={previousLeaders} />
      </div>

      <Footer />
    </div>
  );
}

export default SCOPE;
