import React from "react";
import "./partners.css";
import msake from "../../assets/msake.png";
import ifmsa from "../../assets/ifmsa.png";

function Partners(props) {
  const openLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="partners section-padding">
      <h2 className="underline gradient-text">Our Partners</h2>

      <div className="partners-logo">
        <div className="partner-logo-info">
          <div className="partner-logo-container">
            <img src={msake} alt="logo" />
          </div>
          <p>MSAKE</p>
        </div>

        <div className="partner-logo-info">
          <div className="partner-logo-container">
            <img src={ifmsa} alt="logo" />
          </div>
          <p
            onClick={() => {
              openLink("https://ifmsa.org/");
            }}
          >
            IFMSA
          </p>
        </div>
      </div>
    </div>
  );
}

export default Partners;
