import React from "react";
import "./contacts.css";

import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";

function Contacts(props) {
  const linkStyle = {
    textDecoration: "none",
    color: "blue",
  };
  return (
    <div>
      <div className="contacts bodyBg section-padding">
        <h2 className="underline gradient-text">Contacts</h2>

        <div className="contacts-details">
          <div className="contacts-details-container">
            <div>
              <MdEmail size={25} color="var(--color-bgRed)" />
              <a style={linkStyle} href="mailto:mkumsa.president@gmail.com">
                mkumsa.president@gmail.com
              </a>
            </div>

            <div>
              <FaPhone size={25} color="var(--color-bgRed)" />
              <a style={linkStyle} href="https://wa.me/254745041443">
                +254 745 041 443
              </a>
            </div>

            <div>
              <IoLocation size={25} color="var(--color-bgRed)" />
              <p>Genera Kago Rd, Thika</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
