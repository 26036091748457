import React from "react";
import {
  Accordion,
  Hero,
  ImageView,
  NavBar,
  ProfileCard,
  TextArea,
} from "../../components";
import { Footer } from "../../containers";

import colors from "./colors";

import "./sCommittee.css";
import { Helmet } from "react-helmet";
import images from "../../assets/images";

function SCOMH(props) {
  const heroDetails = {
    color: colors.scomh,
    title: "Mental Health (SCOMH)",
    description:
      "The standing committee on Mental Health aims to unite students who care deeply about the overall well-being of their peers. This committee focuses on addressing and promoting mental health in a comprehensive way.",
  };

  const content = [
    {
      title: "Mission",
      text: `To promote mental health awareness, improve access to mental health services, and advocate for policies that ensure equitable and quality care for individuals affected by mental health issues.`,
    },

    {
      title: "Vision",
      text: `A society where mental health is prioritized, stigma is eliminated, and everyone has access to comprehensive mental health support and services, fostering well-being and resilience in all spaces.`,
    },

    {
      title: "Objectives",
      text: ` <h5>Advocacy</h5>
      <p>Advocate for mental health policies, funding, and programs that address the needs of individuals and communities.</p>
</br>
      <h5>Awareness and Education</h5>
      <p>Increase awareness of mental health issues through campaigns, workshops, and seminars, aimed at reducing stigma and promoting mental well-being.</p>
      </br>
      <h5>Collaboration</h5>
      <p>Work with healthcare providers, policymakers, educational institutions, and community organizations to create integrated support systems for mental health care.</p>
      </br>
      <h5>Research and Development</h5>
      <p>Support research initiatives to advance understanding of mental health conditions, treatment methodologies, and public health strategies.</p>
      </br>
      <h5>Accessibility</h5>
      <p>Ensure access to affordable, equitable, and culturally appropriate mental health services for all populations, particularly to medical students.</p>
      </br>
      <h5>Crisis Intervention</h5>
      <p>Develop and promote crisis intervention systems and resources to respond effectively to individuals experiencing mental health emergencies.</p>
      </br>
      <h5>Support Services</h5>
      <p>Facilitate peer support programs, community-based mental health services, and resource centers that provide ongoing assistance to individuals, students, and families.</p>

      </br> </br>
      credits @ Amani`,
    },
  ];

  const galleryImages = [
    // {
    //   image: hero,
    //   description: "the research team",
    // },
    // {
    //   image: hero,
    //   description: "the research team",
    // },
  ];

  const leaders = [
    {
      name: "Amani Karuiru",
      role: "Local Officer",
      email: "amanikariuki34@gmail.com",
      linkedin: "",
      picture: images.amani,
    },
    {
      name: "Ann Muinde",
      role: "Assistant Local Officer",
      email: "muindeann27@gmail.com",
      linkedin: "",
      picture: images.ann,
    },
  ];

  const previousLeaders = [
    { title: "2023", content: "Elsa Jepkios" },
    { title: "2022", content: "Cassandra Jepkoech" },
    { title: "2021", content: "Dr. James Njoroge Mwangi" },
  ];
  return (
    <div>
      <Helmet>
        <title>SCOMH</title>

        <meta
          name="description"
          content="The standing committee on Mental Health aims to unite students who care deeply about the overall well-being of their peers. This committee focuses on addressing and promoting mental health in a comprehensive way."
        />
      </Helmet>
      <NavBar />
      <Hero details={heroDetails} />
      <div className="specificCommittee bodyBg section-padding">
        {content.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
          </div>
        ))}

        <h2 className="underline gradient-text">Leadership</h2>
        <div className="leaders-container">
          {leaders.map((leader, index) => (
            <div key={index}>
              <ProfileCard leader={leader} />
            </div>
          ))}
        </div>
        <h2 className="underline gradient-text">Gallery</h2>
        <div className="image-container">
          {galleryImages.map((details, index) => (
            <div key={index}>
              <ImageView details={details} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Previous Leaders</h2>

        <Accordion details={previousLeaders} />
      </div>

      <Footer />
    </div>
  );
}

export default SCOMH;
