import React from "react";
import {
  Accordion,
  Hero,
  ImageView,
  NavBar,
  ProfileCard,
  TextArea,
} from "../../components";
import { Footer } from "../../containers";

import colors from "./colors";

import "./sCommittee.css";
import { Helmet } from "react-helmet";
import images from "../../assets/images";

function SCORE(props) {
  const heroDetails = {
    color: colors.score,
    title: "Research Exchanges (SCORE)",
    description: `Unfortunately, many medical students do not have the opportunity to experience research while in medical school. Research is essential for the development of medicine and without it, the medical field could never have reached the stage it is at now with innovative methods of diagnosis and treatments. Put together the experience of improving research skills with an unforgettable cultural learning opportunity and you have the Standing Committee on Research Exchange (SCORE)!`,
  };

  const content = [
    {
      title: "Mission",
      text: `Our mission is to offer future physicians an opportunity to experience research and diversity in countries all over the world. This is achieved by providing a network of locally and internationally active students that globally facilitate access to research exchange projects. Through our programming and opportunities, we aim to develop both culturally sensitive students and skilled researchers intent on shaping the world of science.`,
    },

    {
      title: "Objectives",
      text: `<ul>
      <li>Enable them to take responsibility for their own learning according to their personal interests.</li>
      <li>Introduce them to the basic principles of medical research.</li>
      <li>Widen their horizons and provide the opportunity to experience different approaches to health care, ethical research standards, medical research, education, and treatment.</li>
      <li>Enhance the academic quality of the medical student curricula and achieve educational benefits of practical and theoretical knowledge in the field of medical research.</li>
      <li>Facilitate collaboration and partnership between medical universities, research institutions and allied medical students across the globe in order to share and spread new achievements in medical research.</li>
    </ul>
    `,
    },

    {
      title: "Main Topics",
      text: `<p>The IFMSA Research Exchange program offers medical students, including those from the Mount Kenya University Medical Students’ Association (MKUMSA), the opportunity to enhance their knowledge in their specific research interests. This program is officially recognized by medical schools and is supported by a mentor who teaches exchange students essential research skills such as literature search, data collection, scientific writing, laboratory work, statistics, and ethics. Upon completion, students may be required to submit a written scientific report or give an oral presentation.</p>

      <p>Research projects fall into several categories, including:</p>

      <ul>
        <li>Basic laboratory research projects</li>
        <li>Clinical research projects with laboratory work</li>
        <li>Clinical research projects without laboratory work</li>
        <li>Global Action Projects (GAP)</li>
      </ul>

      <p>The exchanges are organized by student volunteers, with each university represented by a Local Officer on Research Exchange (LORE) who coordinates local projects and arranges housing and boarding for exchange students. Each student is also paired with a local contact person who acts as a guide and resource, promoting intercultural communication and unity among medical students globally. This program provides MKUMSA members with a valuable opportunity to engage in meaningful research and foster international collaboration.</p>
      `,
    },
  ];

  const galleryImages = [
    // {
    //   image: hero,
    //   description: "the research team",
    // },
    // {
    //   image: hero,
    //   description: "the research team",
    // },
  ];

  const leaders = [
    {
      name: "Sam Ngugi",
      role: "Local Officer",
      email: "",
      linkedin: "",
      picture: images.sam,
    },
    {
      name: "Francis Koinange",
      role: "Assistant Local Officer",
      email: "franciskoinax@gmail.com",
      linkedin:
        "https://www.linkedin.com/in/francis-koinange-8066482a4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      picture: images.francis,
    },
  ];

  const previousLeaders = [
    { title: "2023", content: "Gabriella Aliwa" },
    { title: "2022", content: "Boaz Onunda" },
    { title: "2021", content: "Dr. Wanjiru Mwangi" },
  ];
  return (
    <div>
      <Helmet>
        <title>SCORE</title>

        <meta name="description" content={heroDetails.description} />
      </Helmet>
      <NavBar />
      <Hero details={heroDetails} />
      <div className="specificCommittee bodyBg section-padding">
        {content.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
          </div>
        ))}

        <h2 className="underline gradient-text">Leadership</h2>
        <div className="leaders-container">
          {leaders.map((leader, index) => (
            <div key={index}>
              <ProfileCard leader={leader} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Gallery</h2>
        <div className="image-container">
          {galleryImages.map((details, index) => (
            <div key={index}>
              <ImageView details={details} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Previous Leaders</h2>

        <Accordion details={previousLeaders} />
      </div>

      <Footer />
    </div>
  );
}

export default SCORE;
