import React from "react";
import "./hero.css";
import images from "../../assets/images";
import { Helmet } from "react-helmet";

// details are color ,image,title,description
function Hero({ details = {}, height }) {
  const style = {
    backgroundImage: `${
      details.color
        ? details.color
        : `linear-gradient(
          to bottom,
          rgb(128, 0, 0, 0.5),
          rgb(152, 43, 28, 0.2)
        )`
    },
          url(${details.image ? details.image : images.defaultHero})`,
    height: height ? height : "100%",
  };

  // `linear-gradient(to bottom, rgb(0, 0, 0,0.9), rgba(39, 40, 46, 0.1))`
  return (
    <div className="hero section-padding" style={style}>
      <Helmet>
        <meta
          property="og:image"
          content={details.image ? details.image : images.defaultHero}
        />
      </Helmet>

      <div className="hero-text">
        <h1 className="gradient-textWhite  ">
          {details.title ? details.title : ""}
        </h1>
        <p>{details.description ? details.description : ""}</p>
      </div>
    </div>
  );
}

export default Hero;
