import React, { useState } from "react";
import "./accordion.css";
import { CiCircleChevUp, CiCircleChevDown } from "react-icons/ci";

// details are titile and content
function Accordion({ details }) {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    // If the same index is clicked, close it. Otherwise, open the clicked one.
    setOpenIndex(openIndex === index ? null : index);
  };

  const style = { color: "var(--color-bgRed)" };

  return (
    <div className="accordion">
      {details.map((detail, index) => (
        <div key={index} className="accordion-card">
          <div
            className="accordion-title"
            onClick={() => toggleAccordion(index)}
          >
            <h4>{detail.title}</h4>
            <div>
              {openIndex === index ? (
                <CiCircleChevUp size={20} style={style} />
              ) : (
                <CiCircleChevDown size={20} style={style} />
              )}
            </div>
          </div>
          {openIndex === index && (
            <p
              className="accordion-content scale-up-center"
              dangerouslySetInnerHTML={{ __html: detail.content }}
            ></p>
          )}
        </div>
      ))}
    </div>
  );
}

export default Accordion;
