const colors = {
  scoph: ` linear-gradient(
        to bottom,
        rgb(250, 124, 61, 1),
        rgb(152, 43, 28, 0.4)
      )`,

  scope: `linear-gradient(
        to bottom,
        rgb(1, 129, 193, 1),
        rgb(152, 43, 28, 0.4)
      )`,

  scorp: `linear-gradient(
    to bottom,
    rgb(95, 144, 63,1),
    rgb(152, 43, 28, 0.4)
  )`,

  scomh: `linear-gradient(
        to bottom,
        rgb(255, 214, 0, 1),
        rgb(152, 43, 28, 0.4)
      )`,

  score: `linear-gradient(
        to bottom,
        rgb(46, 74, 157, 1),
        rgb(152, 43, 28, 0.4)
      )`,

  scome: ` linear-gradient(
        to bottom,
        rgb(0, 0, 0, 1),
        rgb(152, 43, 28, 0.4)
      )`,

  scora: ` linear-gradient(
        to bottom,
        rgb(189, 32, 43, 1),
        rgb(152, 43, 28, 0.4)
      )`,
};

export default colors;
