import React from "react";
import {
  Accordion,
  Hero,
  ImageView,
  NavBar,
  ProfileCard,
  TextArea,
} from "../../components";
import { Footer } from "../../containers";
import colors from "./colors";

import "./sCommittee.css";
import { Helmet } from "react-helmet";
import images from "../../assets/images";

function SCORA(props) {
  const heroDetails = {
    color: colors.scora,
    title:
      "Sexual and Reproductive Health and Rights including HIV and AIDS (SCORA)",
    description:
      "The Standing Committee on Sexual and Reproductive Health and Rights including HIV and AIDS is a gathering of passionate individuals who are committed to promote sexual and reproductive health and rights and are arduous about creating positive change in their local communities.",
  };

  const content = [
    {
      title: "Vision",
      text: `A world where every individual is empowered to exercise their sexual and reproductive health rights equally, free from stigma and discrimination.`,
    },

    {
      title: "Mission",
      text: `To provide our members with the tools necessary to advocate for sexual and reproductive health and rights within their respective communities in a culturally respected fashion. This has been accomplished through building the skills and the knowledge about, providing trainings on Comprehensive Sexuality Education other respective reproductive health issues, exchanging ideas and projects, as well as drafting policies and working with our external partners in order to create change in local, regional and international level.`,
    },

    {
      title: "Objectives",
      text: `To raise awareness on topics related to HIV and AIDS and sexual and reproductive health.
        </br>
        To decrease the stigma and discrimination against people living with HIV and AIDS.
        </br>
        To raise awareness and increase knowledge about facts, scientific research, global agreements and documents concerning sexual and reproductive health.
        </br>
        Promote positive sexuality and healthy sexual life.
        </br>
        Advocating for policies concerning sexual and reproductive health and represent medical student worldwide
        </br>
        Collaborate and facilitate joint actions concerning medical education, public health, and human rights.
        </br>
        Provide tools for capacity building for future healthcare professionals in terms of sexual and reproductive health and rights`,
    },

    {
      title: "Main topics",
      text: `SCORA is highly committed to raise awareness about sexual and reproductive health through education. We have immense experience in training new peer educators through International Peer Education Trainings and Advanced Peer Education Trainings. This has subsequently led to a network of peer educators who are actively working to organize events at local, national, regional and international level.
        </br></br>
         <h4> Gender - Based Violence </h4>
         SCORAngels are committed to fight against violence (physical, mental or social)  including Female Genital Mutilation (FGM), sexual harassment and domestic violence. `,
    },
  ];

  const galleryImages = [
    // {
    //   image: hero,
    //   description: "the research team",
    // },
    // {
    //   image: hero,
    //   description: "the research team",
    // },
  ];

  const leaders = [
    {
      name: "Joy Omwansa",
      role: "Local Officer",
      email: "joyomwansa131@gmail.com",
      linkedin: "",
      picture: images.joy,
    },
    {
      name: "Nicole Chelangat",
      role: "Assistant Local Officer",
      email: "",
      linkedin: "",
    },
  ];

  const previousLeaders = [
    { title: "2023", content: "Nkatha" },
    { title: "2022", content: "Nanyu Mishi" },
    { title: "2021", content: "Dr. Hafswa Khamis Nassor" },
  ];
  return (
    <div>
      <Helmet>
        <title>SCORA</title>

        <meta name="description" content={heroDetails.description} />
      </Helmet>
      <NavBar />
      <Hero details={heroDetails} />
      <div className="specificCommittee bodyBg section-padding">
        {content.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
          </div>
        ))}

        <h2 className="underline gradient-text">Leadership</h2>
        <div className="leaders-container">
          {leaders.map((leader, index) => (
            <div key={index}>
              <ProfileCard leader={leader} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Gallery</h2>
        <div className="image-container">
          {galleryImages.map((details, index) => (
            <div key={index}>
              <ImageView details={details} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Previous Leaders</h2>

        <Accordion details={previousLeaders} />
      </div>

      <Footer />
    </div>
  );
}

export default SCORA;
