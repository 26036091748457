import React from "react";
import "./notFound.css";
import { Link } from "react-router-dom";

function NotFound(props) {
  return (
    <div className="notFound section-padding bodyBg">
      <h1 className="underline gradient-text"> 404 Page Not found </h1>
      <br />
      <Link className="redButton" to="/">
        Return Home
      </Link>
    </div>
  );
}

export default NotFound;
