const images = {
  defaultHero: "https://i.postimg.cc/W1RQRzy0/mbchb-26-20241005-0001.jpg",
  mkumsaEb: "https://i.postimg.cc/zG6MwBCy/mkumsaEb.jpg",
  scopeHero: "https://i.postimg.cc/nztP5PwZ/Scope-Hero.jpg",
  scorpBg: "https://i.postimg.cc/DZgBNyXd/scorpBg.jpg",
  SCommitteeHero: "https://i.postimg.cc/xTyMyFLM/mbchb-26-20241005-0002.jpg",
  scoraBg: "https://i.postimg.cc/mrMbCKHG/scora-Hero.jpg",
  extravaganza: "https://i.postimg.cc/MpV04pkM/extravaganza.jpg",
  swimming: "https://i.postimg.cc/TPCc8sg8/swimming.jpg",
  amani: "https://i.postimg.cc/0NF3Dh4G/amani.jpg",
  ann: "https://i.postimg.cc/RFGHjzzK/ann.jpg",
  job: "https://i.postimg.cc/sfhBYSM5/job.jpg",
  joy: "https://i.postimg.cc/fyDhS632/joy.jpg",
  kerubo: "https://i.postimg.cc/xjxszwbX/Kerubo.jpg",
  loyce: "https://i.postimg.cc/Y9J3CW9M/loyce.jpg",
  mutugi: "https://i.postimg.cc/ZnxdzBvf/mutugi.jpg",
  salome: "https://i.postimg.cc/HWznHCPP/salome.jpg",
  sam: "https://i.postimg.cc/GmkZwbX4/sam.jpg",
  francis: "https://i.postimg.cc/MZtQKBFw/francis.jpg",
  codeCommunity: "https://i.postimg.cc/y8CBMQy8/code-Community.jpg",
  header: "https://i.postimg.cc/nLzDmhj5/header.jpg",
  mbchbFa: "https://i.postimg.cc/nc2r1tJt/mbchbFa.jpg",
  missMkumsa: "https://i.postimg.cc/GpG1qkys/miss-Mkumsa.jpg",
  mkusart: "https://i.postimg.cc/Nf9C6QTK/mkusart.jpg",
  val: "https://i.postimg.cc/nrsbtx8G/IMG-20241008-WA0014.jpg",
  clubs: "https://i.postimg.cc/fWpdYvbV/clubs1.jpg",
  kamrie: "https://i.postimg.cc/HVW6YYpm/kamrie2.jpg",
  patron: "https://i.postimg.cc/vDtPwgwX/patron.jpg",
  scoph1: "https://i.postimg.cc/Czvmd31q/scoph1.jpg",
  scoph2: "https://i.postimg.cc/TKF0Jnn2/scoph2.jpg",
  scorp2: "https://i.postimg.cc/K1RQT0ks/scorp2.jpg",
  scorp3: "https://i.postimg.cc/kVSc1DyQ/scorp3.jpg",
};
export default images;
