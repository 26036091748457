import React from "react";
import "./sCommittee.css";
import { Hero, NavBar, SCcard, TextArea } from "../../components";
import { Footer } from "../../containers";
import { scora, score, scomh, scope, scorp, scoph, scome } from "./imports.js";

import { Helmet } from "react-helmet";
import images from "../../assets/images";

function SCommittees(props) {
  const colors = {
    scoph: "#f47d3b",
    scope: "rgb(1, 129, 193)",
    scorp: "rgb(95, 144, 63)",
    // scomh: "rgb(255, 214, 0)",
    scomh: "var(--color-text)",
    score: "rgb(46, 74, 157)",
    scome: "rgb(0, 0, 0)",
    scora: "#bd202a",
  };
  const detailsHero = {
    title: "Standing Committees",
    description: `One of the main pillars of MKUMSA,is based on the most relevant health related topics for medical students.`,
    image: images.SCommitteeHero,
  };

  const SCdescription = [
    {
      title: "What are Standing Committees ?",
      text: `These Committees tend to address most relevant health related topics for medical students i.e public health , research , health advocacy , mental health e.t.c`,
    },
    {
      title: "Structure?",
      text: `<ul>
  <li>At the local level (MKUMSA) we have seven standing committees each headed by a local officer and an assistant local officer</li> <br />
<li>The standing committees are the association liason body to MSAKE  ( medical students association of Kenya) on their respective standing committees</li>
</ul>`,
    },
  ];

  const detailsSC = [
    {
      logo: scope,
      title: "Professional Exchanges (SCOPE)",
      text: `The Professional Exchange program is a full educational program offering clerkships to medical students abroad.`,
      color: "",
      background: colors.scope,
      link: "/standing-committees/scope",
    },
    {
      logo: scome,
      title: "Medical Education (SCOME)",
      text: `The Standing Committee On Medical Education (SCOME) is a dedicated organ which aims to implement an optimal learning environment for all medical students around the world.`,
      color: "",
      background: colors.scome,
      link: "/standing-committees/scome",
    },
    {
      logo: score,
      title: "Research Exchanges (SCORE)",
      text: `The Standing Committee on Research Exchange (SCORE) mission is to offer future physicians an opportunity to experience research and diversity in countries all over the world.`,
      color: "",
      background: colors.score,
      link: "/standing-committees/score",
    },
    {
      logo: scomh,
      title: "Mental Health (SCOMH) ",
      text: `The standing committee on Mental Health aims to unite students who care deeply about the overall well-being of their peers. This committee focuses on addressing and promoting mental health in a comprehensive way.`,
      color: "black",
      background: colors.scomh,
      link: "/standing-committees/scomh",
    },

    {
      logo: scora,
      title:
        "Sexual and Reproductive Health and Rights including HIV and AIDS (SCORA)",
      text: `The Standing Committee on Sexual and Reproductive Health and Rights including HIV and AIDS is a gathering of passionate individuals who are committed to promote sexual and reproductive health and rights and are arduous about creating positive change in their local communities.`,
      color: "",
      background: colors.scora,
      link: "/standing-committees/scora",
    },
    {
      logo: scorp,
      title: "Human Rights & Peace (SCORP)",
      text: `The Standing Committee on Human Rights and Peace unite students who strive to create an equal and peaceful world and believe in international, intercultural as well as interpersonal solidarity. We believe in everybody’s responsibility as well as the ability to contribute to creating this world, as human rights can only exist when it applies to all humans.`,
      color: "",
      background: colors.scorp,
      link: "/standing-committees/scorp",
    },

    {
      logo: scoph,
      title: "Public Health (SCOPH)",
      text: `The Standing Committee on Public Health (SCOPH) brings together medical students from all over the world to learn, build skills, cooperate, explore and share ideas when it comes to addressing all issues related to public health, including global health issues, health policies, health promotion and education, activities.`,
      color: "",
      background: colors.scoph,
      link: "/standing-committees/scoph",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Standing Committees</title>
        <meta
          name="description"
          content="One of the main pillars of MKUMSA,is based on the most relevant health related topics for medical students."
        />
      </Helmet>
      <NavBar />
      <div className="bodyBg SCommittee ">
        <Hero details={detailsHero} height="75vh" />
        <TextArea details={SCdescription} />
        {SCdescription.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
          </div>
        ))}

        <h3 className="gradient-text  scCards-title ">
          {" "}
          Overview of theStanding Committees
        </h3>
        <div className="scCards-container">
          {detailsSC.map((details, index) => (
            <div key={index}>
              <SCcard details={details} />
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SCommittees;
