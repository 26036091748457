import React from "react";
import "./publication.css";
import { PublicationCard } from "../../components";

import { GiBookshelf } from "react-icons/gi";
import { FaBookOpen } from "react-icons/fa";
import { GoLaw } from "react-icons/go";

function Publication(props) {
  const details = [
    {
      logo: (
        <GiBookshelf
          className="publicationUnderline"
          size={30}
          color="var(--color-text)"
        />
      ),
      text: "Study guides",
      link: "https://linktr.ee/medschoolsurvivalguide",
    },
    {
      logo: (
        <FaBookOpen
          className="publicationUnderline"
          size={30}
          color="var(--color-text)"
        />
      ),
      text: "MKUMSA Mirror editions",
      link: "https://drive.google.com/drive/folders/1i2uTOtYASba2w-HzGrP3d6uMbfSHH8uc",
    },
    {
      logo: (
        <GoLaw
          className="publicationUnderline"
          size={30}
          color="var(--color-text)"
        />
      ),
      text: "MKUMSA Constitution",
      link: "https://drive.google.com/drive/folders/1VMSVG72SKiU9Fu4RN4qyBcR2tOZ6eT3r",
    },
  ];
  return (
    <div className="publication section-padding">
      <div className="publication-text">
        <h2 className="publicationUnderline">Publications</h2>
      </div>

      <div className="publication-cards">
        {details.map((details, index) => (
          <div key={index}>
            <PublicationCard details={details} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Publication;
