import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
import images from "../../assets/images";
// import blog from "../../assets/blog.jpg";

function Header(props) {
  const linkStyle = {
    textDecoration: "none",
    color: "var(--color-text)",
  };

  const headerStyle = {
    backgroundImage: `linear-gradient(
      to top, rgb(0, 0, 0),
      rgba(39, 40, 46, 0.4)

    ),
    url(${images.header})`,
  };
  return (
    <div style={headerStyle} className="header section-padding">
      <div className="header-text">
        <h1 className="gradient-text">MKUMSA</h1>
        <p>
          The Mount Kenya University Medical Students' Association (MKUMSA) is
          the local organization that accommodates the medical students of Mount
          Kenya University. MKUMSA is affiliated with The Medical Students'
          Association of Kenya (MSAKE) and The International Federation of
          Students Association (IFMSA).....
        </p>

        <Link to="/about-us" style={linkStyle} className="redButton">
          Read more
        </Link>
      </div>
    </div>
  );
}

export default Header;
