import React, { useEffect, useState } from "react";
import "./navbar.css";
import logo from "../../assets/mkumsa.png";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import BackToTop from "../backToTop/BackToTop";

function Navbar(props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const linkStyle = {
    textDecoration: "none",
    color: isSticky ? "black" : "white",
  };

  const linkStylePhone = {
    textDecoration: "none",
    color: "black",
  };

  // this will make the navbar sticky when the user starts scrolling

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // this will make it imposible to scroll if the toogle menu is open
  // Effect to toggle body scrolling
  useEffect(() => {
    if (toggleMenu) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = "auto"; // Restore scrolling
    }

    // Clean up function to restore scroll when the component unmounts or when toggleMenu changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [toggleMenu]);

  const handleMenuToggle = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <div className={`navbar section-padding ${isSticky ? "sticky" : ""}`}>
      <BackToTop />
      <div className="navbar-logo">
        <Link to="/" className="navbar-logo-img">
          <img src={logo} alt="logo" />
        </Link>

        <Link to="/" style={linkStyle}>
          MKUMSA
        </Link>
      </div>

      <div className="navbar-links">
        <Link to="/about-us" style={linkStyle}>
          {" "}
          AboutUs
        </Link>
        <Link to="/standing-committees" style={linkStyle}>
          {" "}
          Standing Committees
        </Link>
        <Link to="/events" style={linkStyle}>
          {" "}
          Events
        </Link>
        <Link to="/gallery" style={linkStyle}>
          {" "}
          Gallery
        </Link>
        <Link to="/faq" style={linkStyle}>
          {" "}
          Faq
        </Link>

        <Link to="/hustle-corner" style={linkStyle}>
          {" "}
          Hustle Corner
        </Link>
      </div>

      <div onClick={handleMenuToggle} className="navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color={isSticky ? "var(--color-bgRed)" : "var(--color-text)"}
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color={isSticky ? "var(--color-bgRed)" : "var(--color-text)"}
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}

        <div className="navbar-menu">
          {/* if the menu is open then open the following */}
          {toggleMenu && (
            <div className="navbar-menu-container scale-up-center">
              <Link to="/" style={linkStylePhone}>
                {" "}
                Home
              </Link>
              <Link to="/about-us" style={linkStylePhone}>
                {" "}
                AboutUs
              </Link>
              <Link to="/standing-committees" style={linkStylePhone}>
                {" "}
                Standing Committees
              </Link>
              <Link to="/events" style={linkStylePhone}>
                {" "}
                Events
              </Link>
              <Link to="/gallery" style={linkStylePhone}>
                {" "}
                Gallery
              </Link>
              <Link to="/faq" style={linkStylePhone}>
                {" "}
                Faq
              </Link>
              <Link to="/hustle-corner" style={linkStylePhone}>
                {" "}
                Hustle Corner
              </Link>

              <Link to="/mkumsa-clubs" style={linkStylePhone}>
                {" "}
                Clubs
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
