import React from "react";
import "./hustleCorner.css";
import { NavBar, TextArea, Hero } from "../../components";
import { Footer } from "../../containers";
import { Helmet } from "react-helmet";

function HustleCorner(props) {
  const openLink = (url) => {
    window.open(url, "_blank");
  };
  const details = {
    title: "Hustle Corner",
    text: ` At MKUMSA, we recognize that many medical students are also young entrepreneurs. We are dedicated to supporting your growth and helping you achieve your business goals.
    </br>
    In our Hustle Corner, we provide a platform for sellers to showcase their products to potential customers within our community. This is a fantastic opportunity to promote your business and connect with fellow students.
    </br>
    If you're interested in having your business featured in this section, please click the registration link below to fill out the form. Join us in celebrating and supporting the entrepreneurial spirit within our medical community!`,
    link: "https://forms.gle/kDk1VJsAH8M44W6Q8",
  };

  return (
    <div>
      <Helmet>
        <title>Hustle Corner</title>
        <meta
          name="description"
          content="At MKUMSA, we recognize that many medical students are also young entrepreneurs. We are dedicated to supporting your growth and helping you achieve your business goals."
        />
      </Helmet>
      <NavBar />
      <Hero details={{ title: "Hustle Corner" }} />
      <div className="hustleCorner bodyBg section-padding">
        <h2 className="underline gradient-text">Coming Soon</h2>
        <TextArea details={details} />
        <button
          onClick={() => {
            openLink(details.link);
          }}
          className="redButton"
        >
          Register
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default HustleCorner;
