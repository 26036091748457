import React, { useEffect, useRef, useState } from "react";
import "./gallery.css";
import { NavBar } from "../../components";
import { Footer } from "../../containers";
import { Helmet } from "react-helmet";

function Gallery() {
  // const images = [
  //   "https://i.postimg.cc/gnfLMst7/mbchb-26-20241016-0001.jpg",
  //   "https://i.postimg.cc/G9tBdVZN/mkumsa-ke-20241016-0002.jpg",
  //   "https://i.postimg.cc/bDCs1923/mkumsa-ke-20241016-0003.jpg",
  //   "https://i.postimg.cc/cKTHNBT0/mkumsa-ke-20241016-0005.jpg",
  //   "https://i.postimg.cc/14d8tMSz/mkumsa-ke-20241016-0006.jpg",
  //   "https://i.postimg.cc/7Cq6cW3K/mkumsa-ke-20241016-0008.jpg",
  //   "https://i.postimg.cc/n9XM294Z/mkumsa-ke-20241016-0012.jpg",
  //   "https://i.postimg.cc/ftTV3rkV/mkumsa-ke-20241016-0017.jpg",
  //   "https://i.postimg.cc/5HmH2V9c/mkumsa-ke-20241016-0019.jpg",
  //   "https://i.postimg.cc/zHHXKZNG/mkumsa-ke-20241016-0026.jpg",
  //   "https://i.postimg.cc/5HJYJ0Vq/mkumsa-ke-20241016-0027.jpg",
  //   "https://i.postimg.cc/nXBjNm6h/mkumsa-ke-20241016-0028.jpg",
  //   "https://i.postimg.cc/gnw6qQsR/mkumsa-ke-20241016-0029.jpg",
  //   "https://i.postimg.cc/grx0fRhb/mkumsa-ke-20241016-0030.jpg",
  //   "https://i.postimg.cc/PNVwDTWT/mkumsa-ke-20241016-0031.jpg",
  //   "https://i.postimg.cc/r0PwDyvH/mkumsa-ke-20241016-0033.jpg",
  //   "https://i.postimg.cc/4Yknvdmk/mkumsa-ke-20241016-0034.jpg",
  //   "https://i.postimg.cc/Vr6Ns0HM/mkumsa-ke-20241016-0037.jpg",
  //   "https://i.postimg.cc/s1gW1vZT/mkumsa-ke-20241016-0048.jpg",
  //   "https://i.postimg.cc/SYRYPvpB/mkumsa-ke-20241016-0051.jpg",
  //   "https://i.postimg.cc/jLJLJNq4/mkumsa-ke-20241016-0056.jpg",
  //   "https://i.postimg.cc/N9gH60Cf/mkumsa-ke-20241016-0058.jpg",
  //   "https://i.postimg.cc/cK73t3mb/mkumsa-ke-20241016-0059.jpg",
  //   "https://i.postimg.cc/JsN0pLky/mku-scome-20241016-0001.jpg",
  //   "https://i.postimg.cc/S2DzDpHL/mku-scome-20241016-0002.jpg",
  // ];

  const images = [
    "https://i.postimg.cc/XqZ7RHRr/Scope-Hero.jpg",
    "https://i.postimg.cc/L62mBsXq/mkumsaEb.jpg",
    "https://i.postimg.cc/nLzDmhj5/header.jpg",
    "https://i.postimg.cc/nc2r1tJt/mbchbFa.jpg",
    "https://i.postimg.cc/GpG1qkys/miss-Mkumsa.jpg",
    "https://i.postimg.cc/Nf9C6QTK/mkusart.jpg",
    "https://i.postimg.cc/fWpdYvbV/clubs1.jpg",
    "https://i.postimg.cc/W1RQRzy0/mbchb-26-20241005-0001.jpg",
    "https://i.postimg.cc/zG6MwBCy/mkumsaEb.jpg",
    "https://i.postimg.cc/nztP5PwZ/Scope-Hero.jpg",
    "https://i.postimg.cc/DZgBNyXd/scorpBg.jpg",

    "https://i.postimg.cc/mrMbCKHG/scora-Hero.jpg",
    "https://i.postimg.cc/MpV04pkM/extravaganza.jpg",
    "https://i.postimg.cc/TPCc8sg8/swimming.jpg",
  ];
  const galleryRef = useRef(null);
  const [fullScreenImg, setFullScreenImg] = useState(null);

  const getVal = (elem, style) => {
    return parseInt(window.getComputedStyle(elem).getPropertyValue(style));
  };

  const getHeight = (item) => {
    return item.querySelector(".content").getBoundingClientRect().height;
  };

  const resizeAll = () => {
    const gallery = galleryRef.current;
    const altura = getVal(gallery, "grid-auto-rows");
    const gap = getVal(gallery, "grid-row-gap");

    gallery.querySelectorAll(".gallery-item").forEach((item) => {
      const el = item;
      el.style.gridRowEnd = `span ${Math.ceil(
        (getHeight(item) + gap) / (altura + gap)
      )}`;
    });
  };

  // Only run this effect when the component mounts
  useEffect(() => {
    const gallery = galleryRef.current;
    if (gallery) {
      resizeAll();

      // Add event listener to each image after it loads
      gallery.querySelectorAll("img").forEach((img) => {
        if (img.complete) {
          resizeAll();
        } else {
          img.addEventListener("load", () => {
            const gitem = img.closest(".gallery-item");
            const altura = getVal(gallery, "grid-auto-rows");
            const gap = getVal(gallery, "grid-row-gap");

            gitem.style.gridRowEnd = `span ${Math.ceil(
              (getHeight(gitem) + gap) / (altura + gap)
            )}`;
          });
        }
      });
    }
  });

  const handleImageClick = (src) => {
    setFullScreenImg(src);
  };

  const closeFullScreen = () => {
    setFullScreenImg(null);
  };

  return (
    <div>
      <Helmet>
        <title>Gallery</title>

        <meta name="description" content="The mkumsa gallery." />
      </Helmet>
      <NavBar />

      <div className="gallery-container paddingTop section-padding">
        <h2 className="gradient-textWhite underline">Gallery</h2>
        <div className="gallery " ref={galleryRef}>
          {images.map((src, index) => (
            <div
              key={index}
              className="gallery-item"
              onClick={() => handleImageClick(src)}
            >
              <div className="content">
                <img src={src} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>

      {fullScreenImg && (
        <div className="full" onClick={closeFullScreen}>
          <div className="content scale-up-center above-navbar">
            <img src={fullScreenImg} alt="Full Screen" />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Gallery;
