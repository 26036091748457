import React from "react";
import "./eventCard.css";
import { Link } from "react-router-dom";

function EventCard({ details }) {
  const style = {
    backgroundImage: `linear-gradient(
            to top,
            rgb(128, 0, 0, 0.9),
            rgb(152, 43, 28, 0.6)
          ),
          url(${details.image})`,
  };

  const linkStyle = {
    textDecoration: "none",
    color: "var(--color-text)",
  };
  return (
    <div className="eventCard" style={style}>
      <h3 className="whiteUnderline gradient-textLight ">{details.event}</h3>
      <p>Date: {details.date}</p>
      <p>{details.description}</p>

      <Link to={details.link} style={linkStyle} className="redButton">
        View
      </Link>
    </div>
  );
}

export default EventCard;
