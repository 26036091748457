import React from "react";
import "./faq.css";
import { Accordion, Hero, NavBar } from "../../components";
import { Footer } from "../../containers";
import { Helmet } from "react-helmet";

function Faq(props) {
  const details = [
    {
      title: "When was MKUMSA established",
      content: ` ...`,
    },
    {
      title: "How to join MKUMSA ?",
      content: `.`,
    },
    {
      title: "Who is Qualifed to vie for a seat in the MKUMSA Executive board",
      content: ` ..`,
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Frequently asked questions about MKUMSA </title>
        <meta
          name="description"
          content="Frequently asked questions about MKUMSA"
        />
      </Helmet>
      <NavBar />
      <Hero details={{ title: "Frequently asked questions about MKUMSA" }} />
      <div className="bodyBg section-padding faq">
        <h2 className="gradient-text underline">Frequently asked Questions</h2>
        <Accordion details={details} />
      </div>

      <Footer />
    </div>
  );
}

export default Faq;
