import React from "react";
import "./footer.css";
import logo from "../../assets/mkumsa.png";
import { Link } from "react-router-dom";
import { FaHeart, FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { TbBrandLinktree } from "react-icons/tb";

function Footer(props) {
  const linkStyle = {
    textDecoration: "none",
    color: "var(--color-text)",
  };

  const openLink = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="footer section-padding">
      <div className="footer-links">
        <Link to="/" className="footer-links-logo">
          <img src={logo} alt="mkumsa logo" />
        </Link>

        <div className="footer-links-quick">
          <h4 className="whiteUnderline">Quick Links</h4>
          <p>
            {" "}
            <Link to="/about-us" style={linkStyle}>
              About Us
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/events" style={linkStyle}>
              Events
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/faq" style={linkStyle}>
              Faq
            </Link>
          </p>
        </div>

        <div className="footer-links-important">
          <h4 className="whiteUnderline">Important Links</h4>

          <p>
            {" "}
            <Link to="/standing-committees" style={linkStyle}>
              Standing Committes
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/gallery" style={linkStyle}>
              Gallery
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/Hustle-corner" style={linkStyle}>
              Hustle Corner
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/mkumsa-clubs" style={linkStyle}>
              Clubs
            </Link>
          </p>

          <Link />
        </div>

        <div className="footer-links-important">
          <h4 className="whiteUnderline">Standing Committees</h4>

          <p>
            {" "}
            <Link to="/standing-committees/scora" style={linkStyle}>
              SCORA
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/standing-committees/score" style={linkStyle}>
              SCORE
            </Link>
          </p>
          <p>
            {" "}
            <Link to="/standing-committees/scorp" style={linkStyle}>
              SCORP
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/standing-committees/scome" style={linkStyle}>
              SCOME
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/standing-committees/scope" style={linkStyle}>
              SCOPE
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/standing-committees/scoph" style={linkStyle}>
              SCOPH
            </Link>
          </p>

          <p>
            {" "}
            <Link to="/standing-committees/scomh" style={linkStyle}>
              SCOMH
            </Link>
          </p>

          <Link />
        </div>
      </div>
      <div className="footer-socials">
        <FaXTwitter
          onClick={() => openLink("https://x.com/mkumsa_ke")}
          title="mkumsa on X"
          size={20}
          color="var(--color-text)"
        />

        <FaInstagram
          onClick={() => openLink("https://www.instagram.com/mkumsa.ke/")}
          title="mkumsa instagram"
          size={20}
          color="var(--color-text)"
        />
        <FaLinkedin
          onClick={() =>
            openLink(
              "https://www.linkedin.com/company/mount-kenya-university-medical-students-association"
            )
          }
          title="mkumsa Linkedin"
          size={20}
          color="var(--color-text)"
        />
        <FaTiktok
          onClick={() => openLink("https://www.tiktok.com/@mkumsa_official")}
          title="mkumsa on Tiktok"
          size={20}
          color="var(--color-text)"
        />

        <TbBrandLinktree
          onClick={() => openLink(" https://linktr.ee/MKUMSAPUBLICATIONS")}
          title="mkumsa linktree"
          size={20}
          color="var(--color-text)"
        />
      </div>

      <div className="footer-credit">
        <a style={linkStyle} href="mailto:mkumedcodecommunity@gmail.com">
          Made with <FaHeart color="var(--color-text)" /> MKU med code community
          © riri
        </a>
      </div>
    </div>
  );
}

export default Footer;
