import React from "react";
import "./textArea.css";

function TextArea({ details }) {
  return (
    <div className="textArea  section-padding">
      <h3 className="gradient-text verticalLine ">{details.title}</h3>
      <p dangerouslySetInnerHTML={{ __html: details.text }}></p>
    </div>
  );
}

export default TextArea;
