import React from "react";
import {
  Accordion,
  Hero,
  ImageView,
  NavBar,
  ProfileCard,
  TextArea,
} from "../../components";
import { Footer } from "../../containers";
import colors from "./colors";

import "./sCommittee.css";
import { Helmet } from "react-helmet";
import images from "../../assets/images";

function SCORP(props) {
  const heroDetails = {
    color: colors.scorp,
    title: "Human Rights & Peace (SCORP)",
    description: `The Standing Committee on Human Rights and Peace unite students who strive to create an equal and peaceful world and believe in international, intercultural as well as interpersonal solidarity. We believe in everybody’s responsibility as well as the ability to contribute to creating this world, as human rights can only exist when it applies to all humans.`,
    image: images.scorpBg,
  };

  const content = [
    {
      title: "Vision",
      text: `SCORP has a vision of a peaceful world where all individuals are entitled to full and equal access to their human rights, where no one is left behind, where priority is given to people in greatest need and where the entire society, including medical students and health workers, unite to support vulnerable groups.`,
    },

    {
      title: "Mission",
      text: `The mission of SCORP is to empower and motivate medical students to actively promote and protect human rights and peace through advocacy, capacity building, and awareness raising, and by supporting the students in carrying out activities and projects that contribute to creating a fair and peaceful world.

`,
    },

    {
      title: "Objectives",
      text: `<ul>
      <li>Enhance students' knowledge on human rights, peacebuilding, humanitarian response, international humanitarian law, and violations of human rights;</li>
      <li>Provide medical students with tools and skills to act according to Human Rights and ethics both in clinical settings and in everyday life;</li>
      <li>Support and create activities, such as campaigns, capacity building, and advocacy, aiming to fulfill the vision of the standing committee;</li>
      <li>Facilitate the Federation's policy development on areas related to human rights and peace;</li>
      <li>Provide members with the opportunity to advocate for the implementation of policies through inclusion in national and international laws and frameworks;</li>
      <li>Collaborate with relevant partners in the implementation of objectives related to human rights and peace.</li>
    </ul>
    `,
    },

    {
      title: "Main Topics",
      text: `<h4> Peace </h4>SCORP understands peace as both the absence of conflict (negative peace) and the presence of equality and harmony (positive peace). </br>
        <h4> Human Rights and Ethics </h4> All of our activities are founded on the Human Rights, but we also try to teach these to other people – children, medical students, the general public – as well as to incorporate them in Medical Education and our profession.</br>

        <h4> Disasters </h4> When a disaster strikes, may it be man-made or natural, the affected population tend to become deprived of basic human rights such as food, water, and shelter. This causes suffering that can be alleviated and in some cases prevented. </br>

        <h4> Vulnerable populations </h4> we often address for example children, elderly people, people with mental or physical impairments, homeless people and others who often face discrimination, negligence or maltreatment.</br>
        `,
    },
  ];

  const galleryImages = [
    {
      image: images.scorp2,
      description: "the research team",
    },
    {
      image: images.scorp3,
      description: "the research team",
    },
  ];

  const leaders = [
    {
      name: "Loyce Magonya",
      role: "Local Officer",
      email: "loycemagonya245@gmail.com",
      linkedin: "",
      picture: images.loyce,
    },
    {
      name: "Peter Gacheru",
      role: "Assistant Local Officer",
      email: "",
      linkedin: "",
    },
  ];

  const previousLeaders = [
    { title: "2023", content: "Samuel Nzaka" },
    { title: "2022", content: "Sally Muthoni" },
    { title: "2021", content: "Kithuci Enock Muriuki" },
  ];
  return (
    <div>
      <Helmet>
        <title>SCORP</title>

        <meta name="description" content={heroDetails.description} />
      </Helmet>
      <NavBar />
      <Hero details={heroDetails} />
      <div className="specificCommittee bodyBg section-padding">
        {content.map((details, index) => (
          <div key={index}>
            <TextArea details={details} />
          </div>
        ))}

        <h2 className="underline gradient-text">Leadership</h2>
        <div className="leaders-container">
          {leaders.map((leader, index) => (
            <div key={index}>
              <ProfileCard leader={leader} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Gallery</h2>
        <div className="image-container">
          {galleryImages.map((details, index) => (
            <div key={index}>
              <ImageView details={details} />
            </div>
          ))}
        </div>

        <h2 className="underline gradient-text">Previous Leaders</h2>

        <Accordion details={previousLeaders} />
      </div>

      <Footer />
    </div>
  );
}

export default SCORP;
