import React from "react";
import "./publicationCard.css";

function PublicationCard({ details }) {
  const openLink = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="publicationCard">
      <div className="publicationCard-icon underline">{details.logo}</div>
      <h4>{details.text}</h4>

      <button
        className="redButton"
        onClick={() => {
          openLink(details.link);
        }}
      >
        View
      </button>
    </div>
  );
}

export default PublicationCard;
