import React from "react";
import "./weCard.css";

function Wecard({ details }) {
  const openLink = (url) => {
    if (url) {
      window.open(url);
    } else {
      console.log("blank who are we link");
    }
  };

  return (
    <div
      onClick={() => {
        openLink(details.link);
      }}
      id="#whoWeAre"
      className="weCard"
    >
      <div className="weCard-icon underline">{details.logo}</div>

      <div className="weCard-text">
        <p>{details.text}</p>
      </div>
    </div>
  );
}

export default Wecard;
